import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {mainAxios} from '@/plugins/axios';
class Origen
{
    origenId?:number = 0;
    descripcion?:string ="";
    estado?:boolean = false;

    constructor(_origenId?:number,_descripcion?:string,_estado?:boolean)
    {
        this.origenId = _origenId;
        this.descripcion  =_descripcion;
        this.estado = _estado;
    }
}

async function  GuardarOrigen(origen:Origen):Promise<ResponseGenerico>
{
    return await Save<Origen>("origen/Guardar",origen,mainAxios);
} 

async function  EditarOrigen(origen:Origen):Promise<ResponseGenerico>
{
    return await Editar<Origen>("origen/Actualizar",origen,mainAxios);
} 

async function  EditarEstadoOrigen(origen:Origen):Promise<ResponseGenerico>
{
    return await Editar<Origen>("origen/CambiarEstado",origen,mainAxios);
} 

async function ObtenerOrigen()
{
    return await Obtener<Origen>("origen/Obtener",mainAxios);
}


async function ObtenerSelectOrigen()
{
    return await Obtener<Origen>("origen/ObtenerSelect",mainAxios);
}

export 
{
    Origen,
    GuardarOrigen,
    EditarOrigen,
    EditarEstadoOrigen,
    ObtenerOrigen,
    ObtenerSelectOrigen
}