














































































import { Espesor ,ObtenerSelectEspesor} from '@/entidades/Maestro/Ktb/Espesor';
import { ObtenerSelectOrigen, Origen } from '@/entidades/Maestro/Ktb/Origen';
import {TipoMadera,ObtenerSelectTipoMadera, GuardartipoMadera, EditartipoMadera} from '@/entidades/Maestro/Ktb/TipoMadera';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';
@Component
export default class FormularioTipoMadera extends Vue
{

  @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
  @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
  @Prop({required:true,type:Number})
  action!:number;
  @Prop({required:false})
  modelo!:TipoMadera;

listadoDescuento:Array<string> =["EXCEDE","TODO","PARCIAL"]
  tipoMaderaId?:number =0;
  descripcion?:string="";
  origenId?:number =0;
  descuento?:string ="";
  vendorSap?:string ="";
  suelto?:number =0;
  bulto?:number =0;
  descuentoValor?:number =0;

  listadoOrigen:Array<Origen> =[];
  //Configuracion de validacion
  @Validations()
     validations = {
         descripcion : {required},
         vendorSap : {required},
         descuento : {required},
         origenId : {required, numeric,minValue:minValue(1)},
         descuentoValor : {required, numeric},
         suelto : {required, numeric},
         bulto : {required, numeric}
     }

 async guardar()
 {
     try
     {
         this.changeLoading(new Loading(true,"Registrando informacion"));
         let response = new ResponseGenerico(false,"");
         let tipoMadera:TipoMadera = new TipoMadera(this.tipoMaderaId,this.origenId,this.descripcion,this.descuento,
         this.vendorSap,this.suelto,this.bulto,this.descuentoValor);
         response = this.action == 1 ?  await GuardartipoMadera(tipoMadera) : await EditartipoMadera(tipoMadera);
         if(response.isSuccess == true)
         {
             this.$emit('limpiar');
         }
         else
         {
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
         }
     }
     catch(error)
     {
         //@ts-ignore
         this.changeAlerta(new Alerta(error,true,"danger",3000));
     }
     finally
     {
         this.changeLoading(new Loading(false,""));
     }
    
 }

 async ObtenerOrigen()
 {
     try
     {
         let rsp = await ObtenerSelectOrigen();
         if(rsp.isSuccess == true)
         {
             this.listadoOrigen = rsp.isResult;
         }
     }
     catch(error)
     {
         console.log(error);
     }
 }
 

 get mensaje()
 {
     if(this.action == 1)
        return {'titulo' : "REGISTRO DE TipoMadera" , 'boton' : "GUARDAR"};
    else
        return {'titulo' : "EDICION DE TipoMadera" , 'boton' : "EDITAR"};
 }

    get mensajeSelectOrigen()
    {
        const errors: string[] = [];
        if(!this.$v.origenId.$dirty) return errors;
        !this.$v.origenId.minValue && errors.push("Valor minimo es 1");
        !this.$v.origenId.required && errors.push("El Campo es requerido");
        return errors;
    }

    get mensajeSelectDescuentoValor()
    {
        const errors: string[] = [];
        if(!this.$v.descuentoValor.$dirty) return errors;
       // !this.$v.descuentoValor.minValue && errors.push("Valor minimo es 1");
        !this.$v.descuentoValor.required && errors.push("El Campo es requerido");
        return errors;       
    }
    get mensajeSelectSuelto()
    {
        const errors: string[] = [];
        if(!this.$v.suelto.$dirty) return errors;
      //  !this.$v.suelto.minValue && errors.push("Valor minimo es 1");
        !this.$v.suelto.required && errors.push("El Campo es requerido");
        return errors;       
    }
    get mensajeSelectBulto()
    {
        const errors: string[] = [];
        if(!this.$v.bulto.$dirty) return errors;
       // !this.$v.bulto.minValue && errors.push("Valor minimo es 1");
        !this.$v.bulto.required && errors.push("El Campo es requerido");
        return errors;       
    }

 mounted() 
 {
    if(this.modelo!=undefined)
    {
        this.tipoMaderaId = this.modelo.tipoMaderaId;
        this.descripcion = this.modelo.descripcion;
        this.origenId = this.modelo.origenId;
        this.descuento = this.modelo.descuento;
        this.descuentoValor = this.modelo.descuentoValor;
        this.suelto = this.modelo.factorHuecoSuelto;
        this.bulto = this.modelo.factorHuecoBulto;
        this.vendorSap = this.modelo.vendorSap;
    }
    //Consultar los demas datos informacion   
    this.ObtenerOrigen();
 }

}
